@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800&display=swap");
@import "./common/variables.css";

/* START - DEFAULT */
body {
  position: relative;
  color: black;
  height: 100vh;
  background: var(--appBackgroundColor);
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  user-select: none;
  cursor: default;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

div {
  color: var(--fontColor);
}

::selection {
  background: var(--selectionColor);
  /* WebKit/Blink Browsers */
}

button {
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

input {
  outline: none;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}

img {
  pointer-events: none;
}

*:focus {
  outline: none;
}

.pageContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* react-toastify */
.toast {
  background: var(--primaryColor) !important;
  margin-bottom: -8px !important;
  margin-top: 16px !important;
}

.Toastify__toast-body {
  color: white;
}
/* END - DEFAULT */

.appButton {
  font-weight: bold;
  transition: background-color 0.35s;
  background-color: var(--primaryColor);
  color: white;
  border: 0;
  user-select: none;
  cursor: pointer;
  border-radius: var(--buttonBorderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
}

.appButton:hover {
  background-color: var(--primaryHoverColor);
}

.appButton:active {
  background-color: var(--primaryActiveColor);
}
