:root {
  --appBackgroundColor: #fafbfe;
  --selectionColor: #0063c51a;
  --fontColor: rgb(21, 44, 91);
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --borderColor: #dcdcdc;

  --primaryBackgroundColor: #ffffff;
  --primaryBackgroundColorHover: #f4f6fa;
  --primaryBackgroundColorActive: #eff2f7;

  --primaryColor: #008aff;
  --primaryHoverColor: #0075d9;
  --primaryActiveColor: #006cca;

  --padding: 14px;
  --appBorderRadius: 14px;
  --buttonBorderRadius: 5px;
}
