@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800&display=swap);
:root {
  --appBackgroundColor: #fafbfe;
  --selectionColor: #0063c51a;
  --fontColor: rgb(21, 44, 91);
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --borderColor: #dcdcdc;

  --primaryBackgroundColor: #ffffff;
  --primaryBackgroundColorHover: #f4f6fa;
  --primaryBackgroundColorActive: #eff2f7;

  --primaryColor: #008aff;
  --primaryHoverColor: #0075d9;
  --primaryActiveColor: #006cca;

  --padding: 14px;
  --appBorderRadius: 14px;
  --buttonBorderRadius: 5px;
}

/* START - DEFAULT */
body {
  position: relative;
  color: black;
  height: 100vh;
  background: var(--appBackgroundColor);
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

div {
  color: var(--fontColor);
}

::selection {
  background: var(--selectionColor);
  /* WebKit/Blink Browsers */
}

button {
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

input {
  outline: none;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}

img {
  pointer-events: none;
}

*:focus {
  outline: none;
}

.pageContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* react-toastify */
.toast {
  background: var(--primaryColor) !important;
  margin-bottom: -8px !important;
  margin-top: 16px !important;
}

.Toastify__toast-body {
  color: white;
}
/* END - DEFAULT */

.appButton {
  font-weight: bold;
  transition: background-color 0.35s;
  background-color: var(--primaryColor);
  color: white;
  border: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  border-radius: var(--buttonBorderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
}

.appButton:hover {
  background-color: var(--primaryHoverColor);
}

.appButton:active {
  background-color: var(--primaryActiveColor);
}

:root {
  --appBackgroundColor: #fafbfe;
  --selectionColor: #0063c51a;
  --fontColor: rgb(21, 44, 91);
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --borderColor: #dcdcdc;

  --primaryBackgroundColor: #ffffff;
  --primaryBackgroundColorHover: #f4f6fa;
  --primaryBackgroundColorActive: #eff2f7;

  --primaryColor: #008aff;
  --primaryHoverColor: #0075d9;
  --primaryActiveColor: #006cca;

  --padding: 14px;
  --appBorderRadius: 14px;
  --buttonBorderRadius: 5px;
}

.AuthPage_contentContainer__1GaKe {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  min-width: 155px;
  max-width: 460px;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 30px 40px 30px 40px;
  border-radius: 18px;
}

.AuthPage_titleAreaContainer__1l8A4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AuthPage_logoContainer__r-ShN {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-right: 15px;
}

.AuthPage_titleContainer__2JJYT {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-top: 5px;
  color: #152c5b;
}

.AuthPage_authInputContainer__3zgq- {
  flex: 1 1;
  box-sizing: border-box;
  position: relative;
  color: hsl(215, 16%, 58%);
  line-height: 27.2px;
  text-align: left;
  -webkit-tap-highlight-color: rgba(31, 45, 61, 0);
}

.AuthPage_authInputLabel__38JmR {
  color: hsl(214, 19%, 29%);
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  line-height: 18.36px;
  /* margin: 0px 0px 8px; */
  text-align: left;
  text-transform: uppercase;
}

.AuthPage_authInput__4L2HB {
  flex: 1 1;
  box-sizing: border-box;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(212, 27%, 90%);
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  color: #273444;
  line-height: 24px;
  padding: 9px 60px 9px 60px;
  font-size: 15px;
  transition: all 0.2s ease;
  width: 0;
  outline: none;
}

.AuthPage_authInput__4L2HB:focus {
  border-color: #008aff;
  box-shadow: 0px 0px 14px 0px rgba(75, 120, 175, 0.1);
}

.AuthPage_authInput__4L2HB::-webkit-input-placeholder {
  color: hsl(213, 26%, 74%);
}

.AuthPage_authInput__4L2HB:-ms-input-placeholder {
  color: hsl(213, 26%, 74%);
}

.AuthPage_authInput__4L2HB::placeholder {
  color: hsl(213, 26%, 74%);
}

.AuthPage_authInput__4L2HB:focus::-webkit-input-placeholder {
  color: rgb(211, 220, 230);
}

.AuthPage_authInput__4L2HB:focus:-ms-input-placeholder {
  color: rgb(211, 220, 230);
}

.AuthPage_authInput__4L2HB:focus::placeholder {
  color: rgb(211, 220, 230);
}

.AuthPage_authInputLeftSide__3g_0g {
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  width: 58px;
  align-items: center;
  color: hsl(213, 26%, 74%);
  display: flex;
  line-height: 24px;
  padding: 12px 20px;
  text-align: center;
}

.AuthPage_authButtonWhite__o0-EE {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  height: 43px;
  font-size: 0.95rem;
  font-weight: 700;
  line-height: 1.5;
  border: 2px solid #e2e8f0;
  border-radius: 0.375rem;
  color: #273444;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.AuthPage_authButtonWhite__o0-EE:hover {
  /* background-color: #f5f5f5; */
  border: 2px solid #008aff;
}

.AuthPage_authButtonWhite__o0-EE:active {
  /* background-color: #f5f5f5; */
  border: 2px solid #008aff;
  color: #008aff;
}

.ScormPage_availableAreaContainer__2qHUO {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding);
}

.ScormPage_tabButton__qPNSI {
  flex: 1 1;
  opacity: 0.5;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;
  padding-bottom: 4px;
  font-weight: bold;
}

.ScormPage_tabButton__qPNSI:hover {
  opacity: 0.7;
}

.ScormPage_contentContainer__2QZAJ {
  display: flex;
  flex-direction: column;
  background-color: var(--primaryBackgroundColor);
  max-height: 400px;
  max-width: 800px;
  height: 100%;
  width: 100%;
  border-radius: var(--appBorderRadius);
  border: 1px solid var(--borderColor);
  box-shadow: var(--boxShadow);
  overflow: hidden;
}

.ScormPage_header__1yJsS {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background-color: var(--primaryBackgroundColor);
  border-bottom-left-radius: var(--appBorderRadius);
  border-bottom-right-radius: var(--appBorderRadius);
  box-shadow: var(--boxShadow);
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding));
}

.ScormPage_listItem__2YQso {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
  padding-left: var(--padding);
}

.ScormPage_listItem__2YQso:hover {
  background-color: var(--primaryBackgroundColorHover);
}

.ScormPage_listItemColumn__3UzXB {
  flex: 0.5 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: var(--padding);
}

.ScormPage_dropzoneContainer__8FJm1 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.2s ease;
}

.ScormPage_dropzoneContainer__8FJm1:hover {
  background-color: var(--primaryBackgroundColorHover);
}

.ScormPage_titleText__1zMO- {
  font-weight: bold;
  font-size: 20px;
}

.ScormPage_boxButtonsRow__3VDXy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ScormPage_primaryWhiteButton__21PVd {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 7px 15px;
  min-width: 100px;
  line-height: 15px;
  height: 38px;
  border: 2px solid #e2e8f0;
  border-radius: 0.4rem;
  color: #273444;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ScormPage_primaryWhiteButton__21PVd:hover {
  /* background-color: #f5f5f5; */
  border: 2px solid #008aff;
}

.ScormPage_primaryWhiteButton__21PVd:active {
  /* background-color: #f5f5f5; */
  border: 2px solid #008aff;
  color: #008aff;
}

.ScormPage_selectedUploadLangugagesItem__3ZT_f {
  background-color: transparent;
}

.ScormPage_selectedUploadLangugagesItem__3ZT_f:hover {
  background-color: var(--primaryBackgroundColorHover);
}

.ScormPage_selectedUploadLangugagesItem__3ZT_f:active {
  background-color: var(--primaryBackgroundColorHover);
}

