@import "../common/variables.css";

.contentContainer {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  min-width: 155px;
  max-width: 460px;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 30px 40px 30px 40px;
  border-radius: 18px;
}

.titleAreaContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-right: 15px;
}

.titleContainer {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-top: 5px;
  color: #152c5b;
}

.authInputContainer {
  flex: 1;
  box-sizing: border-box;
  position: relative;
  color: hsl(215, 16%, 58%);
  line-height: 27.2px;
  text-align: left;
  -webkit-tap-highlight-color: rgba(31, 45, 61, 0);
}

.authInputLabel {
  color: hsl(214, 19%, 29%);
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  line-height: 18.36px;
  /* margin: 0px 0px 8px; */
  text-align: left;
  text-transform: uppercase;
}

.authInput {
  flex: 1;
  box-sizing: border-box;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(212, 27%, 90%);
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  color: #273444;
  line-height: 24px;
  padding: 9px 60px 9px 60px;
  font-size: 15px;
  transition: all 0.2s ease;
  width: 0;
  outline: none;
}

.authInput:focus {
  border-color: #008aff;
  box-shadow: 0px 0px 14px 0px rgba(75, 120, 175, 0.1);
}

.authInput::placeholder {
  color: hsl(213, 26%, 74%);
}

.authInput:focus::placeholder {
  color: rgb(211, 220, 230);
}

.authInputLeftSide {
  box-sizing: border-box;
  pointer-events: none;
  position: absolute;
  width: 58px;
  align-items: center;
  color: hsl(213, 26%, 74%);
  display: flex;
  line-height: 24px;
  padding: 12px 20px;
  text-align: center;
}

.authButtonWhite {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  height: 43px;
  font-size: 0.95rem;
  font-weight: 700;
  line-height: 1.5;
  border: 2px solid #e2e8f0;
  border-radius: 0.375rem;
  color: #273444;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;
}

.authButtonWhite:hover {
  /* background-color: #f5f5f5; */
  border: 2px solid #008aff;
}

.authButtonWhite:active {
  /* background-color: #f5f5f5; */
  border: 2px solid #008aff;
  color: #008aff;
}
