@import "../common/variables.css";

.availableAreaContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding);
}

.tabButton {
  flex: 1;
  opacity: 0.5;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;
  padding-bottom: 4px;
  font-weight: bold;
}

.tabButton:hover {
  opacity: 0.7;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--primaryBackgroundColor);
  max-height: 400px;
  max-width: 800px;
  height: 100%;
  width: 100%;
  border-radius: var(--appBorderRadius);
  border: 1px solid var(--borderColor);
  box-shadow: var(--boxShadow);
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background-color: var(--primaryBackgroundColor);
  border-bottom-left-radius: var(--appBorderRadius);
  border-bottom-right-radius: var(--appBorderRadius);
  box-shadow: var(--boxShadow);
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding));
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
  padding-left: var(--padding);
}

.listItem:hover {
  background-color: var(--primaryBackgroundColorHover);
}

.listItemColumn {
  flex: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: var(--padding);
}

.dropzoneContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.2s ease;
}

.dropzoneContainer:hover {
  background-color: var(--primaryBackgroundColorHover);
}

.titleText {
  font-weight: bold;
  font-size: 20px;
}

.boxButtonsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.primaryWhiteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 7px 15px;
  min-width: 100px;
  line-height: 15px;
  height: 38px;
  border: 2px solid #e2e8f0;
  border-radius: 0.4rem;
  color: #273444;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;
}

.primaryWhiteButton:hover {
  /* background-color: #f5f5f5; */
  border: 2px solid #008aff;
}

.primaryWhiteButton:active {
  /* background-color: #f5f5f5; */
  border: 2px solid #008aff;
  color: #008aff;
}

.selectedUploadLangugagesItem {
  background-color: transparent;
}

.selectedUploadLangugagesItem:hover {
  background-color: var(--primaryBackgroundColorHover);
}

.selectedUploadLangugagesItem:active {
  background-color: var(--primaryBackgroundColorHover);
}
